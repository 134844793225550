import { Input } from "./ui/input";
import { Button } from "./ui/button";

export function SearchInput(props) {
  return (
    <form action="/search" className="relative flex w-full items-stretch">
      <Input type="search" name="search" placeholder="Search" defaultValue={props?.defaultValue} required />
      <div className="absolute right-0">
        <Button className="rounded-l-none">Search</Button>
      </div>
    </form>
  );
}
